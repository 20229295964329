.container-dragdrop {
    /* transform: translateY(-100%); */
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    flex-wrap: wrap;
align-content: center;
width: 700px; */
}

.container .errorData {
    color: red;
    text-align: center;
    
   
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
   /* width: 70vw; */
   /* min-width: 240%; */
    height: 28vh;
    border: 4px dashed var(--airco2-green-color);
}

.upload-icon {
    width: 50px;
    height: 50px;
    background: url(./../../../images/upload2.png) no-repeat center center; 
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px;
}

.message-container{
    display: flex;
    justify-content: center;
 
}

.drop-message {
    text-align: center;
    color:var(--airco2-green-color);
    font-family: Arial;
    font-size: 15px;
    max-width: 30vw;


  
}

.body-content{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
/* 
.box-drag{
    justify-content: center;
    display: flex;
    flex-direction: column;
   align-items: center;
  padding-top: 35vh;

} */

.content{

   display: flex;
   margin-top: 15px;
   /* min-height: max-content; */



  

}

.title{
    text-align: center;
    color:var(--airco2-green-color);
    font-family: Arial;
    font-size: 20px;



}

.file-container{
    display: flex;
    justify-content: center;

   
}
.file-display-container {
 
  display: flex;
  flex-direction: column;
  
  
 
}

.file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}

.file-status-bar > div {
    overflow: hidden;
}

.file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: var(--airco2-green-color);
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    margin-top: 10px;
    margin-left: 15px;
    width: 30px;
    height: 30px;
    background: url(./../../../images/archivo.png)no-repeat center center; 
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 18px;
    right: -20px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    font-weight: bold;

}

.modal{
    z-index: 999;
    display: none;
    overflow: hidden;
}

.modal .overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.66);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.close {
    position: absolute;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color:var(--airco2-green-color);
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
}

.file-input {
    display: none;
} 



.upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.upload-modal .overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.66);
    position: absolute;
    top: 0;
    left: 0;
}

.progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
}

.progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}

.progressInvoice {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;

  
}

.progress-bar-invoice {
    position: absolute;
    background-color: var(--airco2-green-color);
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    max-width: 100%;
 
    
}


/* .progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
  
} */

/* .progress-bar {
    position: absolute;
    background-color: var(--airco2-green-color);
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    
} */
.error {
    color: red;
    
}