:root {--airco2-grey-color: #878787;
--airco2-green-color: #8dd4bd;
--airco2-light-green-color: rgba(141, 212, 189, 0.3);
}
.header-top {
    margin-top: 80px;
}
.header-glossary-top{
    margin-top: 80px;
}

.bg-footer {
    background: linear-gradient(to bottom, var(--airco2-light-green-color) 40%, var(--airco2-green-color)) 40%;
    width: 100%;
}

.img-forest{
    position: relative;
}

.img-community {
    width: 200px !important;
    height: 200px !important;
    border-radius: 50% !important;
  }
  

@media (min-width: 768px) {

    .header-top {
        margin-top: 96px;
        height: 70vh !important;
        overflow-x: hidden;
        position: relative;
    }

    .header-glossary-top {
        margin-top: 96px;
        height: 50vh !important;
        overflow-x: hidden;
        position: relative;
    }


    .img-forest{
        position: absolute;
        bottom: -150px;
        right: 0px;
        width: 100%
    }
    


    .md\:w-\[640px\] {
        min-width: 640px;
    }
}